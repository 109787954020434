import React from 'react';
import { faBan, faCheck, faUndo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Input } from '../../components/Input'
import { Load } from '../../components/Load'
import { ICreateUsuario, IUsuarios } from '../../global/types'
import { useAdmContext } from '../../hooks/useAdmContext'
import { api } from '../../services/api'
import './style.css'

type ParamsProps = {
  tipo: 'new' | 'edit'
}

type IParams = {
  id: string
}

export function UsuariosForm({ tipo }: ParamsProps) {
  const navigate = useNavigate()

  const { id } = useParams() as IParams
  const { tokens, checkToken, usuario } = useAdmContext()

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState('')

  const [cadastro, setCadastro] = useState<ICreateUsuario>({} as ICreateUsuario)

  const loadCadastro = useCallback(async () => {
    setLoading(true);
    setTxtLoading('Carregando cadastro...');

    const canLoad = await checkToken();
    if (!canLoad)
      return;

    await api.get(`usuarios/${id}`)
      .then((res) => {
        if (!res.data)
          return;

        const cad = res.data as IUsuarios;

        setCadastro({
          id: cad.id,
          nome: cad.nome,
          email: cad.email,
          admin: cad.admin
        });

      })
      .catch((err) => {
        if (err.response?.data?.msg)
          alert(err.response?.data?.msg);
        else
          alert('Erro ao tentar recuperar o usuário. Tente novamente mais tarde.');
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading('');
      });
  }, [checkToken, id]);

  const handleSalvar = async () => {
    setLoading(true);
    setTxtLoading('Salvando usuário...');

    try {

      if (cadastro.nome.length < 3 || cadastro.email.length <= 3)
        return alert('Preencha todos os campos corretamente');

      if (!cadastro.id && (!cadastro.senha || cadastro.senha.length <= 2))
        return alert('Preencha o campo senha corretamente');

      const canLoad = await checkToken();
      if (!canLoad)
        return;

      await api.post(`usuarios`, { ...cadastro });

      navigate('/adm/usuarios');

    } catch (err: any) {
      if (err.response?.data?.msg)
        alert(err.response.data.msg);
      else
        alert('Erro ao salvar usuário. Tente novamente mais tarde.');
    } finally {
      setLoading(false);
      setTxtLoading('');
    }
  }

  useEffect(() => {
    if (tokens?.token && tipo === 'edit')
      loadCadastro()
  }, [tokens, tipo, loadCadastro])

  return (
    <div id='pgUsuariosForm'>

      {!usuario?.admin && (
        <div style={{ textAlign: 'center', marginTop: '15px' }}>
          <FontAwesomeIcon icon={faBan} style={{ fontSize: '70px', color: '#ff5349' }} />
          <h2 style={{ marginTop: '15px' }}>USUÁRIO SEM PERMISSÃO</h2>
        </div>
      )}

      {loading && (
        <Load txtLoading={txtLoading} />
      )}

      {!loading && usuario?.admin && (
        <>
          <div className='painel form'>
            <p className='title'>{tipo === 'new' ? 'Novo' : 'Editando'} Cadastro</p>
            <span style={{ width: '49%' }}>
              <label>Nome</label>
              <Input
                mask='none'
                value={cadastro.nome}
                inputMaskChange={(v) => setCadastro(e => ({ ...e, nome: v }))}
              />
            </span>

            <span style={{ width: '49%' }}>
              <label>Usuário</label>
              <Input
                mask='none'
                value={cadastro.email}
                inputMaskChange={(v) => setCadastro(e => ({ ...e, user: v }))}
              />
            </span>

            <span style={{ width: '49%' }}>
              <label>Senha</label>
              <Input
                type='password'
                mask='none'
                value={cadastro.senha}
                inputMaskChange={(v) => setCadastro(e => ({ ...e, senha: v }))}
                placeholder={tipo === 'edit' ? 'Para não alterar a senha deixe esse campo vazio' : ''}
              />
            </span>

            <span style={{ width: '49%' }}>
              <label>Admin?</label>
              <select value={cadastro.admin} onChange={(e) => setCadastro(c => ({ ...cadastro, admin: parseInt(e.target.value) }))}>
                <option value='1'>Sim</option>
                <option value='0'>Não</option>
              </select>
            </span>

          </div>

          <div className='btnPainel'>
            <button className='btn-purple' onClick={() => { navigate(-1) }}>
              <FontAwesomeIcon icon={faUndo} />
              VOLTAR
            </button>

            <button className='btn-green' onClick={() => { handleSalvar() }}>
              <FontAwesomeIcon icon={faCheck} />
              SALVAR
            </button>
          </div>
        </>
      )}
    </div>
  )
}