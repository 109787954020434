import React from "react"
import Lottie from "lottie-react-web"
import loading from "../../assets/loading.json";

import "./style.css";

type OptionProps = {
  width: number;
  height: number;
}

type ParamsProps = {
  txtLoading: string;
  options?: OptionProps;
}

export function Load({ txtLoading, options }: ParamsProps) {
  return (
    <div id="comp-load">
      <Lottie options={{ animationData: loading }} width={options ? options.width : 300} height={options ? options.height : 300} />
      <span>{txtLoading}</span>
    </div>
  );
}