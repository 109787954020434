import React, { KeyboardEvent, useCallback, useEffect, useState } from "react"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Input } from "../../components/Input"
import { Load } from "../../components/Load"
import { IClientes } from "../../global/types"
import { useAdmContext } from "../../hooks/useAdmContext"
import { api } from "../../services/api"

import "./style.css"
import { Pagination } from "../../components/Pagination"

export function Clientes() {
  const { tokens, checkToken } = useAdmContext()

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState("")

  const [list, setList] = useState<IClientes[]>([])
  const [pesquisa, setPesquisa] = useState("")

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const limitPage = 25

  const loadList = useCallback(async (pesq?: string) => {

    const canLoad = await checkToken()
    if (!canLoad)
      return;

    setLoading(true)
    setTxtLoading("Carregando lista...")

    await api.get(`clientes?limit=${limitPage}&page=${page}&filter_nome=${pesq ? pesq : ""}`)
      .then((res) => {
        if (!res.data)
          return

        setList(res.data.result)

        const newMaxPage = Math.ceil(res.data.total / limitPage);
        setMaxPage(newMaxPage < 1 ? 1 : newMaxPage);

      })
      .catch((err) => {
        if (err.response?.data?.msg)
          alert(err.response.data.msg)
        else
          alert("Erro ao tentar recuperar a lista. Tente novamente mais tarde")
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
      })
  }, [checkToken, page])

  const handlePesquisaKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      loadList();
  }

  useEffect(() => {
    console.log(tokens);

    if (tokens?.token)
      loadList()
  }, [tokens, loadList])

  useEffect(() => {
    document.title = "ADM - Clientes"
  }, [])

  return (
    <div id="pgClientes">

      {loading && (
        <Load txtLoading={txtLoading} />
      )}

      {!loading && (
        <>
          <div className="topo">
            <Input
              mask="none"
              value={pesquisa}
              inputMaskChange={(v) => { setPesquisa(v) }}
              placeholder="Pesquisa por nome"
              onKeyUp={(e) => handlePesquisaKeyUp(e)}
            />

            <button onClick={() => { loadList(pesquisa) }} className="btn-purple">
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>

          <div className="painel table">
            <div className="head">
              <span style={{ flex: '2' }}>Nome</span>
              <span style={{ flex: '1' }}>CPF</span>
              <span style={{ flex: '1' }}>E-mail</span>
            </div>

            <div className="tableContent">
              {list && list.map(item => (
                <div className="rowTable" key={item.id}>
                  <span style={{ flex: '2' }}>{item.nome}</span>
                  <span style={{ flex: '1' }}>{item.cpf}</span>
                  <span style={{ flex: '1' }}>{item.email}</span>
                </div>
              ))}
            </div>
          </div>

          <Pagination maxPage={maxPage} page={page} setPage={setPage} />

        </>
      )}

    </div>
  )
}