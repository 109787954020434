import React from "react"
import { faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./style.css"

type ParamsProps = {
  page: number
  maxPage: number
  setPage: (value: React.SetStateAction<number>) => void
}

export function Pagination({ page, maxPage, setPage }: ParamsProps) {
  return (
    <div id="pagination">
      {page !== 1 && (
        <FontAwesomeIcon icon={faAngleDoubleLeft} style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => setPage(i => --i)} />
      )}
      <p>
        {page}/{maxPage}
      </p>
      {page !== maxPage && (
        <FontAwesomeIcon icon={faAngleDoubleRight} style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => setPage(i => ++i)} />
      )}
    </div>
  )
}