import { faCartShopping, faCheckDouble, faRotateLeft, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useNavigate } from "react-router-dom"
import { Quantidade } from "../../components/Quantidade"
import { ICarrinho } from "../../global/types"
import { useSiteContext } from "../../hooks/useSiteContext"
import { currencyFormatter } from "../../utils/currencyFormatter"

import "./style.css"

export function Carrinho() {
  const navigate = useNavigate()

  const { carrinho, saveCarrinho } = useSiteContext()

  const handleRemove = (item: ICarrinho) => {
    if (!window.confirm(`Deseja realmente remover o curso "${item.curso.nome}" do seu carrinho?`))
      return

    const newCarrinho: ICarrinho[] = []

    carrinho.forEach(rs => {
      if (rs.curso.id !== item.curso.id)
        newCarrinho.push(rs)
    })

    saveCarrinho(newCarrinho)
  }

  const handleAdd = (item: ICarrinho) => {
    const newCarrinho: ICarrinho[] = []

    carrinho.forEach(rs => {
      if (rs.curso.id === item.curso.id)
        newCarrinho.push({
          quantidade: rs.quantidade + 1,
          curso: item.curso
        })
    })

    saveCarrinho(newCarrinho)
  }

  const handleMinus = (item: ICarrinho) => {
    if (item.quantidade === 1)
      return handleRemove(item)

    const newCarrinho: ICarrinho[] = []

    carrinho.forEach(rs => {
      if (rs.curso.id === item.curso.id)
        newCarrinho.push({
          quantidade: rs.quantidade - 1,
          curso: item.curso
        })
    })

    saveCarrinho(newCarrinho)
  }

  return (
    <div id="pgCarrinho">

      <h2>Carrinho</h2>

      {carrinho.map((item, i) => (
        <div className="painel" key={`c-${i}`}>
          <FontAwesomeIcon className="removeItem" icon={faTrash} onClick={() => handleRemove(item)} />

          <p className="nomeCurso">
            {item.curso.nome}
          </p>

          <Quantidade
            Qtd={item.quantidade}
            btnMais={() => handleAdd(item)}
            btnMenos={() => handleMinus(item)}
            limite={-1}
            requestWidth={120}
          />

          <div className="valorCurso">
            <small>
              {item.quantidade} x {currencyFormatter(parseFloat(`${item.curso.valor}`))}
            </small>
            {currencyFormatter(parseFloat(`${item.curso.valor}`) * item.quantidade)}
          </div>
        </div>
      ))}

      <div>
        {carrinho.length === 0 && (
          <div className="cartEmpty">
            <FontAwesomeIcon icon={faCartShopping} />
            <p>CARRINHO VAZIO</p>
          </div>
        )}
      </div>

      <div className="dvBtn">
        <button className="btnFinalizar" onClick={() => { navigate("/") }} style={{ background: "#688edb" }}>
          <FontAwesomeIcon icon={faRotateLeft} />
          CONTINUAR COMPRANDO
        </button>

        {carrinho.length > 0 && (
          <button className="btnFinalizar" onClick={() => navigate("/lgnCliente")}>
            <FontAwesomeIcon icon={faCheckDouble} />
            FINALIZAR
          </button>
        )}
      </div>

    </div>
  )
}