function maskCep(value: string) {
  value = value.replace(/\D/g, ""); // 1239856
  value = value.replace(/^(\d{5})(\d)/, "$1-$2");
  return value;
}

function maskPhone(value: string) {
  value = value.replace(/\D/g, "");
  // (11)1111-1111
  value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
  value = value.replace(/(\d)(\d{4})$/, "$1-$2");
  return value;
}

function maskFirebasePhone(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d)/g, "$1 $2");
  value = value.replace(/(\d)(\d{4})$/, "$1-$2");
  return value;
}

function maskCurrency(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
  return value;
}

function maskCpfCnpj(value: string) {
  value = value.replace(/\D/g, "");

  if (value.length < 14) {
    //CPF
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    //CNPJ
    value = value.replace(/^(\d{2})(\d)/, "$1.$2");
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
    value = value.replace(/(\d{4})(\d)/, "$1-$2");
  }

  return value;
}

function maskCreditCard(value: string) {
  value = value.replace(/\D/g, "");

  value = value.replace(/(\d{4})(\d)/, "$1 $2");
  value = value.replace(/(\d{4})(\d)/, "$1 $2");
  value = value.replace(/(\d{4})(\d)/, "$1 $2");
  value = value.replace(/(\d{4})(\d)/, "$1 $2");

  return value;
}

function maskDate(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d{2})/g, "$1/$2");
  value = value.replace(/(\d{2})(\d{4})$/, "$1/$2");
  return value;
}

function maskHour(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d{2})/g, "$1:$2");
  return value;
}

function maskNumber(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  return value;
}

export { maskCep, maskPhone, maskFirebasePhone, maskCurrency, maskCpfCnpj, maskDate, maskHour, maskCreditCard, maskNumber };
