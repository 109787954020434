import { faCartShopping, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { KeyboardEvent, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { ICursos } from "../../global/types";
import { api } from "../../services/api";
import { currencyFormatter } from "../../utils/currencyFormatter";

import "./style.css"

type IParams = {
  pg: string
}

export function HomeSite() {
  const navigate = useNavigate()
  const { pg } = useParams() as IParams

  const [loading, setLoading] = useState(false)
  const [txtLoading, setTxtLoading] = useState("")

  const [list, setList] = useState<ICursos[]>([])
  const [txtPesquisa, setTxtPesquisa] = useState("")
  const [pesquisa, setPesquisa] = useState("")

  const [page, setPage] = useState(pg && parseInt(pg) > 0 ? parseInt(pg) : 1)
  const [maxPage, setMaxPage] = useState(1)
  const limitPage = 20

  const [nrPages, setNrPages] = useState<number[]>([])

  const loadList = useCallback(async (p: number, pesq?: string) => {
    setLoading(true)
    setTxtLoading("Carregando lista...")

    await api.get(`cursos?limit=${limitPage}&page=${p}&filter_nome=${pesq ? pesq : ""}&list_enabled_buy=1`)
      .then((res) => {
        if (!res.data)
          return

        setList(res.data.result)

        const newMaxPage = Math.ceil(res.data.total / limitPage);

        if (newMaxPage !== maxPage)
          setMaxPage(newMaxPage < 1 ? 1 : newMaxPage);

      })
      .catch((err) => {
        if (err.response?.data?.msg)
          alert(err.response.data.msg)
        else
          alert("Erro ao tentar recuperar a lista. Tente novamente mais tarde")
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
      })

  }, [])

  const handleSearch = () => {
    setPesquisa(txtPesquisa)
    navigate("/cursos/1")
  }

  const handlePesquisaKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter")
      handleSearch();
  }

  useEffect(() => {
    document.title = "Regenerativa - Venda de Cursos";
  }, [])

  useEffect(() => {
    setPage(parseInt(pg))
  }, [pg])

  useEffect(() => {
    if (!isNaN(page) && page <= maxPage && page > 0) {
      const aPages: number[] = []
      let nrMax = (page + 2) > maxPage ? maxPage : page + 2
      let nrMin = (page - 1) > 1 ? page - 1 : page

      if (page <= 4) {
        nrMin = 1
        nrMax = maxPage > 6 ? 6 : maxPage
      }

      if (nrMin > (nrMax - 4) && nrMin !== 1)
        nrMin = nrMax - 4

      for (let i = nrMin; i <= nrMax; i++)
        aPages.push(i)

      setNrPages(aPages)
      loadList(page, pesquisa)
    }
  }, [maxPage, page, loadList, pesquisa])

  return (
    <div id="pgHomeSite">

      {loading && (
        <Load txtLoading={txtLoading} />
      )}

      {!loading && (
        <>

          <div className="dvSearch">
            <span>
              <Input
                mask="none"
                inputMaskChange={(v) => setTxtPesquisa(v)}
                value={txtPesquisa}
                placeholder="Pesquisa por curso"
                onKeyUp={(e) => handlePesquisaKeyUp(e)}
              />
              <button onClick={() => { handleSearch() }}>
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </span>
          </div>

          <div className="listCursos">

            {list.map(item => (
              <div className="item" key={`i-${item.id}`}>
                <div className="imgContent">
                  {item.capa && item.capa.length > 4 && (
                    <img src={`${process.env.REACT_APP_BASE_URL}/archives/imgCursos/${item.id}/${item.capa}`} alt={item.nome} />
                  )}
                </div>
                <p className="title">{item.nome}</p>
                <p className="description">
                  {item.descricao}
                </p>
                <div className="footer">
                  <p className="price">{currencyFormatter(parseFloat(`${item.valor}`))}</p>
                  <div className="btn" onClick={() => { navigate(`/curso/${item.id}`) }}>
                    <FontAwesomeIcon icon={faCartShopping} />
                    VER MAIS
                  </div>
                </div>
              </div>
            ))}

          </div>

          <div className="pagination">
            <>
              {page > 4 && (
                <>
                  <Link to={`cursos/1`} className={`${page === 1 ? "selected" : ""}`}>
                    1
                  </Link>
                  <p>...</p>
                </>
              )}

              {nrPages.map(i => (
                <Link
                  key={`pg-${i}`}
                  className={`${page === i || (isNaN(page) && i === 1) ? "selected" : ""}`}
                  to={`/cursos/${i}`}
                >
                  {i}
                </Link>
              ))}


              {page <= (maxPage - 4) && (
                <p>...</p>
              )}

              {maxPage > 1 && (page + 3) <= 100 && (
                <Link to={`/cursos/${maxPage}`}>
                  {maxPage}
                </Link>
              )}
            </>
          </div>

        </>
      )}

    </div>
  )
}