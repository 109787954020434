import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export function convertToUTC(date: Date): string {
  dayjs.extend(utc);
  return dayjs(date).format('DD/MM/YYYY HH:mm');
}

export function convertDateToBr(date: Date): string {
  dayjs.extend(utc);
  return dayjs(date).utc().format('DD/MM/YYYY');
}

export function convertToDiaMes(date: Date): string {
  dayjs.extend(utc);
  return dayjs(date).utc().format('DD/MM');
}

export function getHourMin(date: Date): string {
  dayjs.extend(utc);
  return dayjs(date).format('HH:mm');
}

export function convertToDate(date: string): Date {
  const data = date.split("/");
  return dayjs(`${data[2]}-${data[1]}-${data[0]}`).toDate();
}

export function convertDataHora(date: string): Date {
  const data = date.split(" ")[0].split("/");
  const hora = date.split(" ")[1].split(":");
  return dayjs(`${data[2]}-${data[1]}-${data[0]} ${hora[0]}:${hora[1]}:00`).toDate();
}

export function dateNow(): Date {
  return dayjs().toDate();
}

export function convertDateSqlToDate(date: string): Date {
  return dayjs(date).toDate();
}

export function convertToUs(date: string): string {
  const data = date.split("/");
  return dayjs(`${data[2]}-${data[1]}-${data[0]}`).format("YYYY-MM-DD");
}

export function convertDateToUs(date: Date): string {
  dayjs.extend(utc);
  return dayjs(date).utc().format("YYYY-MM-DD");
}

export function addYears(years: number): string {
  const data = dayjs().add(years, "years");
  return data.format("DD/MM/YYYY");
}

export function addMonths(months: number): Date {
  const data = dayjs().add(months, "months").toDate();
  return data;
}

export function addMinutes(minutes: number): Date {
  const data = dayjs().add(minutes, "minutes").toDate();
  return data;
}

export function compareInDays(start_date: Date, end_date: Date): number {
  return dayjs(end_date).diff(start_date, "days");
}

export function getDateInSeconds(date: Date): number {
  return dayjs(date).unix();
}

export function minutesToHours(minutes: number): string {
  const interval = [
    Math.floor(minutes / 60).toString(),
    (minutes % 60).toString()
  ];

  return interval[0].padStart(2, '0') + ':' + interval[1].padStart(2, '0')
}

export function compareInHoursAndMin(start: string, end: string): string {
  const fromtime = dayjs(`2000-01-01 ${start}`)
  const totime = dayjs(`2000-01-01 ${end}`)

  const minutes = totime.diff(fromtime, "minute")

  return minutesToHours(minutes)
}