import React, { useCallback, useEffect, useState } from "react"
import { faTimes, faToggleOff, faToggleOn, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ICursos } from "../../global/types"
import { Load } from "../../components/Load"
import { FileUploader } from "react-drag-drop-files"

import "./style.css"
import { useAdmContext } from "../../hooks/useAdmContext"
import { api } from "../../services/api"

type IProps = {
  closeModal: () => void
  curso: ICursos | undefined
}

type IList = {
  index: number
  list: any[]
}

export function CursoImages({ closeModal, curso: paramCurso }: IProps) {
  const { checkToken } = useAdmContext()

  const [loading, setLoading] = useState(false)
  const [loadingImgs, setLoadingImgs] = useState(false)
  const [txtLoading, setTxtLoading] = useState("")

  const [images, setImages] = useState<string[]>([])

  const [selectedFiles, setSelectedFiles] = useState<any>(null);
  const [fileList, setFileList] = useState<IList>({} as IList)

  const handleChangeSelected = useCallback(async (imgs: any) => {

    if (!curso) return
    if (imgs === null) return

    setLoading(true)
    setTxtLoading("Verificando usuário...")

    const canLoad = await checkToken()
    if (!canLoad) return

    const listFiles = []
    for (var x = 0; x < imgs.length; x++)
      listFiles.push(imgs[x])

    setFileList({
      index: 0,
      list: listFiles
    })
  }, [checkToken])

  const handleSendImg = useCallback(async (file: IList, c: ICursos) => {
    if (!c)
      return

    setLoading(true);

    try {
      setTxtLoading(`Enviado imagem ${file.index + 1}/${file.list.length}`)
      const item = file.list[file.index]

      const formData = new FormData();
      formData.append("image", item, item.name);
      formData.append("id_curso", `${c.id}`);

      await api.post("cursos/sendImg", formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (file.index + 1 < file.list.length)
        setFileList(e => ({ ...e, index: e.index + 1 }))
      else {
        setLoading(false);
        setTxtLoading("");

        setFileList({} as IList)
        setSelectedFiles(null)
      }
    } catch (err: any) {
      if (err.response?.data?.msg)
        alert(err.response?.data?.msg);
      else {
        console.log(err);
        alert("Erro ao enviar imagem para o servidor");
      }
      setLoading(false);
      setTxtLoading("");
    }
  }, [])

  const [curso, setCurso] = useState<ICursos | undefined>(paramCurso)

  const loadImages = useCallback(async (c: ICursos) => {
    setLoadingImgs(true)
    await api.get(`cursos/getImgs?id_curso=${c.id}`)
      .then(res => {
        if (!res.data)
          return

        setImages(res.data)
      })
      .catch(err => {
        if (err?.response?.data?.msg)
          alert(err.response.data.msg)
        else
          alert("Erro ao carregar images.")
      })
      .finally(() => {
        setLoadingImgs(false)
      })
  }, [])

  const handleDelete = async (image: string) => {
    try {
      if (image === curso?.capa)
        throw new Error("Não é permitido excluir a imagem de capa. Selecione outra imagem como capa antes de excluir.");

      if (!window.confirm("Deseja realmente excluir essa imagem?"))
        return;

      setLoading(true)
      setTxtLoading("Excluindo imagem...")

      await api.post("cursos/deleteImg", {
        id: curso?.id,
        img: image
      })
        .then(() => {
          const newList: string[] = []

          images.forEach(item => {
            if (item !== image)
              newList.push(item)
          })

          setImages(newList)
        })
        .catch(err => {
          if (err?.response?.data?.msg)
            alert(err.response.data.msg)
          else
            alert("Erro ao excluir imagem")
        })
        .finally(() => {
          setLoading(false)
          setTxtLoading("")
        })

    } catch (error: any) {
      alert(error.message)
    }
  }

  const handleSaveCapa = async (image: string) => {
    setLoading(true)
    setTxtLoading("Salvando edição de capa principal")

    await api.post("cursos/saveCapa", {
      id: curso?.id,
      capa: image
    })
      .then(() => {
        if (curso)
          setCurso({ ...curso, capa: image })
      })
      .catch(err => {
        if (err?.response?.data?.msg)
          alert(err.response.data.msg)
        else
          alert("Erro ao carregar images.")
      })
      .finally(() => {
        setLoading(false)
        setTxtLoading("")
      })
  }

  useEffect(() => {
    if (selectedFiles !== null)
      handleChangeSelected(selectedFiles)
  }, [selectedFiles])

  useEffect(() => {
    if (curso && fileList.index < fileList.list?.length)
      handleSendImg(fileList, curso)

    if (curso)
      loadImages(curso)
  }, [fileList, curso])

  return (
    <div id="mdlCursoImages">
      <div className="title">
        <p>Imagens: {curso?.nome}</p>
        <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={closeModal} />
      </div>

      <div className="body">
        {loading && (
          <Load txtLoading={txtLoading} />
        )}

        {!loading && curso && (
          <>
            <FileUploader
              handleChange={setSelectedFiles}
              name="file"
              types={["JPG", "PNG"]}
              onSizeError={() => { alert("Selecione uma imagem menor") }}
              hoverTitle="Solte aqui"
              label="Carregue ou solte as imagens aqui"
              classes="dropFile"
              multiple
            />

            <div className="imagesContent">

              {loadingImgs && (
                <Load txtLoading="" />
              )}

              {!loadingImgs && curso && images.map(item => (
                <div className="imgCard" key={item}>
                  <img src={`${process.env.REACT_APP_BASE_URL}/archives/imgCursos/${curso.id}/${item}`} />
                  <div className="footCard">
                    {curso.capa && curso.capa === item && (
                      <FontAwesomeIcon icon={faToggleOn} />
                    )}

                    {(!curso.capa || curso.capa !== item) && (
                      <FontAwesomeIcon icon={faToggleOff} onClick={() => handleSaveCapa(item)} />
                    )}

                    <FontAwesomeIcon icon={faTrash} onClick={() => handleDelete(item)} />
                  </div>
                </div>
              ))}
            </div>

          </>
        )}

      </div>

    </div>
  )
}