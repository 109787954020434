import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"

import "./style.css"

type Props = {
  btnMais: () => void
  btnMenos: () => void
  requestWidth: number
  limite: number
  Qtd: number
}

export function Quantidade({
  btnMais,
  btnMenos,
  requestWidth,
  Qtd,
  limite,
}: Props) {


  const handlebtnMais = () => {
    if (limite === -1 || Qtd < limite) {
      btnMais()
    }
  }

  const handleBtnMenos = () => {
    if (Qtd > 0) btnMenos()
  }

  return (
    <div className="btn-qtd-container" style={{ width: `${requestWidth}px` }}>
      <button onClick={handleBtnMenos}>
        <FontAwesomeIcon icon={faMinus} className="icons" />
      </button>
      <span>{Qtd}</span>
      <button onClick={handlebtnMais}>
        <FontAwesomeIcon icon={faPlus} className="icons" />
      </button>
    </div>
  )
}
